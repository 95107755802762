import { ThemeProvider, CssBaseline, Grid, Container, Stack, Avatar, Typography, Box, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import {Phone, Mail, FacebookOutlined} from '@mui/icons-material'

import Theme from "../../assets/Theme"
import Navbar from "../../components/Navbar"
import Header from "./components/Header"
import ContactForm from "../../components/ContactForm"

const makeStyles = theme => ({
    section: {
        py: 5
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none'
    },
    contactAvatar: {
        width: '55px',
        height: '55px',
        background: '#00A3FF'
    }
})

const Contact = () => {

    const theme = useTheme()
    const styles = makeStyles(theme)

    return (
        <ThemeProvider theme={Theme.Light}>
            <CssBaseline />

            <ThemeProvider theme={Theme.Dark}>
                <Navbar />
                <Header />
            </ThemeProvider>

            <Box component="section" sx={styles.section}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} lg={6} paddingBottom={{xs: 5, lg: 0}}>
                            <Stack direction="column" rowGap={2}>
                                <Link to="tel:01917432274" style={styles.link}>
                                    <Stack direction="row" columnGap={2}>
                                        <Avatar sx={styles.contactAvatar}>
                                            <Phone />
                                        </Avatar>
                                        <Stack direction="column">
                                            <Typography variant="h6" fontWeight="bold">
                                                Phone number
                                            </Typography>
                                            <Typography variant="body1">
                                                0191 743 2274
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Link>
                                <Link to="mailto:support@stargaterentals.com" style={styles.link}>
                                    <Stack direction="row" columnGap={2}>
                                        <Avatar sx={styles.contactAvatar}>
                                            <Mail />
                                        </Avatar>
                                        <Stack direction="column">
                                            <Typography variant="h6" fontWeight="bold">
                                                Email address
                                            </Typography>
                                            <Typography variant="body1">
                                                support@stargaterentals.com
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Link>
                                <Link to="https://www.facebook.com/stargaterentals" target="_blank" style={styles.link}>
                                    <Stack direction="row" columnGap={2}>
                                        <Avatar sx={styles.contactAvatar}>
                                            <FacebookOutlined />
                                        </Avatar>
                                        <Stack direction="column">
                                            <Typography variant="h6" fontWeight="bold">
                                                Facebook
                                            </Typography>
                                            <Typography variant="body1">
                                                Stargate Rentals
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Link>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={6} paddingBottom={{xs: 5, lg: 0}}>
                            <ContactForm />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </ThemeProvider>
    )
}

export default Contact 