import { Box, Container, Toolbar, Typography } from "@mui/material"

import Background from '../../../assets/Background3.jpg'

const makeStyles = theme => ({
    section: {
        py: {
            xs: 5,
            lg: 15
        },
        backgroundImage: `linear-gradient(rgba(0, 4, 53, 1) 10%, rgba(0, 4, 53, 0.33)), url('${Background}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'text.primary'
    }
})

const Header = () => {

    const styles = makeStyles()

    return (
        <Box component="section" sx={styles.section}>
            <Container maxWidth="large">
                <Toolbar />

                <Typography variant="h4" fontWeight="bold" textAlign="center">
                    Contact Us
                </Typography>

                <Typography variant="body1" textAlign="center" mt={2}>
                    Get in touch to discuss renting a Private Hire car from us
                </Typography>

            </Container>
        </Box>
    )
}

export default Header