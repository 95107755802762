import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Avatar, Box, Button, Container, IconButton, Typography } from "@mui/material"
import { ChevronRight } from "@mui/icons-material"
import Aos from "aos"

import CarCard from "../../../components/CarCard"
import { describeCars, fetchCars } from "../../../services/backend"

const makeStyles = theme => ({
    section: {
        py: 5
    },
    carList: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2,
        flexWrap: 'wrap',
        overflow: 'scroll',
    },
    showMore: {
        mt: 2,
        textAlign: 'right',
        '.MuiButton-root': {
            paddingRight: 0,
            paddingLeft: 0,
            '&:hover': {
                background: 'transparent',
            }
        },
        '.MuiAvatar-root': {
            width: '30px',
            height: '30px',
            background: '#000435'
        }
    }
})

const AvailableCars = () => {

    const navigate = useNavigate()

    const styles = makeStyles()

    const [description, setDescription] = useState()
    const [cars, setCars] = useState([])

    useEffect(() => {
        describeCars()
            .then(({data: description}) => setDescription(description))
            .catch(console.log)

        fetchCars(0, 3)
            .then(({data: {cars}}) => {
                setCars(cars || [])
            })  
            .catch(console.log)
    }, [])

    useEffect(() => {
        if (cars.length == 0)
            return

        Aos.init()
    }, [cars])

    return (
        <Box component="section" sx={styles.section}>
            <Container maxWidth="lg">
                <Typography variant="h4" fontWeight="bold" textAlign="center" paddingBottom={5}>
                    Available Private Hire Cars
                </Typography>

                {!!cars && !!description && (
                    <Typography variant="body2" textAlign="right" mb={2}>
                        Showing {cars.length} of {description.cars} cars
                    </Typography>
                )}

                <Box component="div" sx={styles.carList}>
                    {cars.map((car, index) => (
                        <CarCard key={car._id.toString()} {...car} aos={index == 0 && 'fade-right' || index == 1 && 'zoom-in' || index == 2 && 'fade-left'} />
                    ))}
                </Box>

                <Box component="div" sx={styles.showMore} onClick={() => navigate('/fleet')}>
                    <Button disableRipple>
                        View more
                        &nbsp;
                        <Avatar>
                            <ChevronRight />
                        </Avatar>
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}

export default AvailableCars