import { Fragment, useRef, useEffect, useState } from "react"
import { AppBar, Box, Toolbar, Tabs, Tab, Typography, Drawer, List, ListItemButton, IconButton} from "@mui/material"
import { Menu } from "@mui/icons-material"

import Logo from '../assets/Logo.png'
import { useLocation, useNavigate } from "react-router-dom"
import { Close } from "@mui/icons-material"

const makeStyles = theme => ({
    logo: {
        maxHeight: '45px',
        cursor: 'pointer'
    },
    links: {
        display: {
            xs: 'none',
            lg: 'block'
        },
        ml: 'auto',
        '.MuiTab-root.Mui-selected': {
            color: 'secondary.main'
        }
    },
    linksMobile: {
        '.MuiTab-root.Mui-selected': {
            color: 'secondary.main'
        },
    },
    expandButton: {
        display: {
            xs: 'flex',
            lg: 'none'
        },
        ml: 'auto'
    }
})

const Navbar = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const activePath = location.pathname.toLowerCase()

    const [expanded, setExpanded] = useState(false)

    const barRef = useRef()

    const styles = makeStyles()

    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            if (!barRef.current)
                return

            const scrollY = window.scrollY

            if (scrollY <= 56) {
                barRef.current.style.background = 'transparent'
                return
            }

            barRef.current.style.background = '#000435'
        })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        setExpanded(false)
    }, [activePath])

    const onNavigate = (undefined, v) => {
        navigate(v)
    }

    return (
        <Fragment>
            <AppBar ref={barRef}>
                <Toolbar>
                    <Box 
                        component="img" 
                        src={Logo} 
                        sx={styles.logo}
                        onClick={() => navigate('/home')}
                    />

                    <Tabs value={activePath} sx={styles.links} onChange={onNavigate}>
                        <Tab value="/home" label="Home" />
                        <Tab value="/fleet" label="Our Fleet" />
                        <Tab value="/faqs" label="FAQs" />
                        <Tab value="/contact" label="Contact Us" />
                    </Tabs>

                    <IconButton
                        onClick={() => setExpanded(_expanded => !_expanded)}
                        sx={styles.expandButton}
                    >
                        {expanded ? <Close /> : <Menu />}
                    </IconButton>   
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="top"
                open={expanded}
                onClose={() => setExpanded(false)}
            >
                <Toolbar />
                <Tabs 
                    orientation="vertical"
                    variant="fullWidth"
                    value={activePath} 
                    sx={styles.linksMobile} 
                    onChange={onNavigate}
                >
                    <Tab value="/home" label="Home" />
                    <Tab value="/fleet" label="Our Fleet" />
                    <Tab value="/faqs" label="FAQs" />
                    <Tab value="/contact" label="Contact Us" />
                </Tabs>
            </Drawer>
        </Fragment>
    )
}

export default Navbar