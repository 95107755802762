import { Avatar, Box, Button, Container, Grid, Stack, TextField, ThemeProvider, Typography, useTheme } from "@mui/material"
import { FacebookOutlined, Mail, Phone } from "@mui/icons-material"

import Theme from "../../../assets/Theme"
import Background from '../../../assets/Background2.jpg'
import ContactForm from "../../../components/ContactForm"
import { useEffect } from "react"
import Aos from "aos"
import ContactFormModal from "../../../components/Popup"
import { Link } from "react-router-dom"

const makeStyles = theme => ({
    section: {
        py: 5,
        backgroundImage: `linear-gradient(to right, rgba(0, 4, 53, 0.75) 10%, rgba(0, 4, 53, 1)), url('${Background}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'text.primary'
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none'
    },
    contactAvatar: {
        width: '55px',
        height: '55px',
        background: '#00A3FF'
    }
})

const Contact = () => {

    const theme = useTheme()
    const styles = makeStyles(theme)

    return (
        <Box component="section" sx={styles.section} id="contact">
            <Container maxWidth={"lg"}>
                <Grid container>
                    <Grid item xs={12} lg={6} paddingBottom={{xs: 5, lg: 0}}>
                        <Typography variant="h4" fontWeight="bold" paddingBottom={5}>
                            How can we help you?
                        </Typography>
                        <Stack direction="column" rowGap={3}>
                            <Typography variant="body1">
                                Get in touch to arrange renting a Private Hire car from us
                            </Typography>
                            <Link to="tel:01917432274" style={styles.link}>
                                <Stack direction="row" columnGap={2}>
                                    <Avatar sx={styles.contactAvatar}>
                                        <Phone />
                                    </Avatar>
                                    <Stack direction="column">
                                        <Typography variant="h6" fontWeight="bold">
                                            Phone number
                                        </Typography>
                                        <Typography variant="body1">
                                            0191 743 2274
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Link>
                            <Link to="mailto:support@stargaterentals.com" style={styles.link}>
                                <Stack direction="row" columnGap={2}>
                                    <Avatar sx={styles.contactAvatar}>
                                        <Mail />
                                    </Avatar>
                                    <Stack direction="column">
                                        <Typography variant="h6" fontWeight="bold">
                                            Email address
                                        </Typography>
                                        <Typography variant="body1">
                                            support@stargaterentals.com
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Link>
                            <Link to="https://www.facebook.com/stargaterentals" target="_blank" style={styles.link}>
                                <Stack direction="row" columnGap={2}>
                                    <Avatar sx={styles.contactAvatar}>
                                        <FacebookOutlined />
                                    </Avatar>
                                    <Stack direction="column">
                                        <Typography variant="h6" fontWeight="bold">
                                            Facebook
                                        </Typography>
                                        <Typography variant="body1">
                                            Stargate Rentals
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ContactForm />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Contact