import axios from 'axios'

const BASE_URL = 'https://api.stargaterentals.com'

export const describeCars = () => axios(`${BASE_URL}/cars/describe`)

export const fetchCars = (page, pageSize) => axios.post(`${BASE_URL}/cars/fetch`, {page, pageSize})

export const describeFAQs = () => axios(`${BASE_URL}/faqs/describe`)

export const fetchFAQs = (page, pageSize) => axios.post(`${BASE_URL}/faqs/fetch`, {page, pageSize})

export const sendMessage = (name, email, phone, message) => axios.post(`${BASE_URL}/enquiry/send`, {name, email, phone, message})