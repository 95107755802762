import { BrowserRouter as Router } from "react-router-dom"
import { ThemeProvider, CssBaseline } from "@mui/material"

import Theme from "./assets/Theme"
import Popup from "./components/Popup"
import DefaultRoutes from "./routes/DefaultRoutes"
import Footer from "./components/Footer"
import Disclaimer from "./components/Disclaimer"

const App = () => {

    return (
        <Router>
            <ThemeProvider theme={Theme.Light}>
                <CssBaseline />

                <Popup>
                    <DefaultRoutes />

                    <ThemeProvider theme={Theme.Dark}>
                        <Footer />
                        <Disclaimer />
                    </ThemeProvider>
                </Popup>
            </ThemeProvider>
        </Router>
    )   
}

export default App