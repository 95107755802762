import { useNavigate } from "react-router-dom"
import { Box, Button, Container, Grid, Stack, Toolbar, Typography } from "@mui/material"
import Aos from "aos"

import Background from '../../../assets/Background.jpg'
import { useEffect } from "react"

const makeStyles = theme => ({
    section: {
        width: '100%',
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `linear-gradient(rgba(0, 4, 53, 1) 10%, rgba(0, 4, 53, 0.33)), url('${Background}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'text.primary'
    }
})

const Header = () => {

    const navigate = useNavigate()

    const styles = makeStyles()

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <Box component="section" sx={styles.section}>
            <Toolbar />

            <Container maxWidth={false}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Stack direction="column" rowGap={4}>
                            <Typography variant="h2" fontWeight="bold" data-aos="fade-right" data-aos-duration="1000">
                                Are you looking to rent a Private Hire car in Newcastle?
                            </Typography>

                            <Typography variant="h6" data-aos="fade-right" data-aos-duration="1000">
                                Then look no futher, we have a fleet of reliable Private Hire cars available to rent at competitive prices
                            </Typography>

                            <Stack direction="row" columnGap={2} data-aos="fade-right" data-aos-duration="1000">
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    fontWeight="bold"
                                    onClick={() => navigate("/fleet")}
                                >
                                    Rent a car
                                </Button>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    color="secondary"
                                    fontWeight="bold"
                                    onClick={() => navigate('/contact')}
                                >
                                    Contact us
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Header