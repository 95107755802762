import { useState, useEffect } from "react"
import { Box, Container, Pagination, Typography, useTheme } from "@mui/material"

import { describeCars, fetchCars } from "../../../services/backend"
import CarCard from "../../../components/CarCard"

const makeStyles = theme => ({
    section: {
        py: 5
    },
    carList: {
        display: 'flex',
        justifyContent: 'space-evenly',
        gap: 2,
        flexWrap: 'wrap',
        overflow: 'scroll',
    }
})

const PAGE_SIZE = 9

const FleetPage = () => {

    const theme = useTheme()

    const styles = makeStyles(theme)

    const [description, setDescription] = useState(undefined)
    const [cars, setCars] = useState([])

    useEffect(() => {
        describeCars()
            .then(({data: description}) => {
                console.log(Math.ceil(description.cars / PAGE_SIZE))
                setDescription({
                    ...description, 
                    pages: Math.ceil(description.cars / PAGE_SIZE),
                    currentPage: 1
                })
            })
            .catch(console.log)
    }, [])

    useEffect(() => {
        if (!description)
            return

        fetchCars(description.currentPage, PAGE_SIZE)
            .then(({data: {cars}}) => {
                setCars(cars)
                window.scrollTo(0, 0)
            })
            .catch((console.log))
    }, [description])

    return (
        <Box component="section" sx={styles.section}>
            <Container maxWidth="lg">
                {!!cars && !!description && (
                    <Typography variant="body2" textAlign="right" mb={2}>
                        Showing {cars.length} of {description.cars} cars
                    </Typography>
                )}

                <Box component="div" sx={styles.carList} mb={2}>
                    {cars.map((car) => (
                        <CarCard key={car._id.toString()} {...car} />
                    ))}
                </Box>

                <Box component="div" display="flex" justifyContent="center">
                    <Pagination 
                        color="primary" 
                        count={description?.pages || 1}
                        page={description?.currentPage || 1}
                        onChange={(undefined, page) => setDescription(_description => ({..._description, currentPage: page}))}            
                    />
                </Box>
            </Container>
        </Box>
    )
}

export default FleetPage