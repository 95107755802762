import { Routes, Route } from "react-router-dom";

import PrivacyPolicy from "../pages/PrivacyPolciy";
import Contact from "../pages/Contact";
import FAQs from "../pages/FAQs";
import Fleet from "../pages/Fleet";
import Home from "../pages/Home";

const DefaultRoutes = () => {

    return (
        <Routes>
            <Route path="privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="contact" element={<Contact />} />
            <Route path="faqs" element={<FAQs />} />
            <Route path="fleet" element={<Fleet />} />
            <Route path="*" element={<Home />} />
        </Routes>
    )
}

export default DefaultRoutes