import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, Typography, Button, Avatar } from "@mui/material"
import { ChevronRight } from '@mui/icons-material'

import { describeFAQs, fetchFAQs } from "../../../services/backend"
import QuestionAnswer from "../../../components/QuestionAnswer"

const makeStyles = theme => ({
    section: {
        py: 5
    },
    questions: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'space-evenly'
    },
    showMore: {
        mt: 2,
        textAlign: 'right',
        '.MuiButton-root': {
            paddingRight: 0,
            paddingLeft: 0,
            '&:hover': {
                background: 'transparent',
            }
        },
        '.MuiAvatar-root': {
            width: '30px',
            height: '30px',
            background: '#000435'
        }
    }
})

const FAQs = () => {

    const navigate = useNavigate()

    const styles = makeStyles()

    const [description, setDescription] = useState()
    const [faqs, setFAQs] = useState([])

    useEffect(() => {
        describeFAQs()  
            .then(({data: description}) => setDescription(description))
            .catch(console.log)

        fetchFAQs(1, 12)
            .then(({data: {faqs}}) => setFAQs(faqs))
            .catch(console.log)
    }, [])

    return (
        <Box component="section" sx={styles.section} id="faqs">
            <Container maxWidth="lg">
                <Typography variant="h4" fontWeight="bold" textAlign="center" paddingBottom={5}>
                    Frequently Asked Questions
                </Typography>

                {!!faqs && !!description && (
                    <Typography variant="body2" textAlign="right" mb={2}>
                        Showing {faqs.length} of {description.faqs} questions
                    </Typography>
                )}
                
                <Grid container spacing={2}>
                    {faqs.map((faq) => (
                        <QuestionAnswer {...faq} key={faq._id.toString()} />
                    ))}
                </Grid>

                <Box component="div" sx={styles.showMore} onClick={() => navigate('/faqs')}>
                    <Button disableRipple>
                        View more
                        &nbsp;
                        <Avatar>
                            <ChevronRight />
                        </Avatar>
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}

export default FAQs