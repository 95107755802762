import { useRef, useState } from 'react'
import {Box, Stack, TextField, Button, Modal, Paper, Typography} from '@mui/material'

import { sendMessage } from '../services/backend'
import { useSetPopup } from './Popup'

const ContactForm = () => {

    const setPopup = useSetPopup()

    const [pending, setPending] = useState(false)
    const [errors, setErrors] = useState({})

    const nameRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()
    const messageRef = useRef()

    const onSend = (e, pending) => {
        e.preventDefault(true)

        if (pending)
            return
        setPending(true)
        setErrors({})

        sendMessage(
            nameRef.current.value, 
            emailRef.current.value,
            phoneRef.current.value,
            messageRef.current.value
        ).then(() => {
            nameRef.current.value = ""
            emailRef.current.value = ""
            phoneRef.current.value = ""
            messageRef.current.value = ""

            setPopup({
                title: 'Message Received',
                message: 'Thank you for expressing your interest in Stargate Rentals. We have received your message and will be in touch with you as soon as possible. Alternatively, please feel free to contact us via telephone if your matter is urgent.'
            })
        }).catch(err => {
            console.log(err)
            setErrors(err?.response?.data?.errors || {})
        })
        .finally(() => setPending(false))
    }

    return (
        <Box 
            component="form" 
            onSubmit={e => onSend(e, pending)}
            disabled={pending}
        >
            <Stack direction="column" rowGap={2}>
                <TextField
                    fullWidth
                    color="secondary"
                    size="large"
                    variant="outlined"
                    type="text"
                    label="Your name"
                    inputRef={nameRef}
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    fullWidth
                    color="secondary"
                    size="large"
                    variant="outlined"
                    type="email"
                    label="Your email"
                    inputRef={emailRef}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    fullWidth
                    color="secondary"
                    size="large"
                    variant="outlined"
                    type="text"
                    label="Your phone"
                    inputRef={phoneRef}
                    error={!!errors.phone}
                    helperText={errors.phone}
                />
                <TextField
                    multiline
                    rows={6}
                    fullWidth
                    color="secondary"
                    size="large"
                    variant="outlined"
                    type="text"
                    label="Message"
                    inputRef={messageRef}
                    error={!!errors.message}
                    helperText={errors.message}
                />
                <Box component="div">
                    <Button
                        type="submit"   
                        variant="contained"
                        size="large"
                        color="secondary"
                    >
                        Send message
                    </Button>
                </Box>
            </Stack>
        </Box>
    )
}

export default ContactForm