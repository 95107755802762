import { Box, Container, Grid, Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"

import Logo from '../assets/Logo.png'

const makeStyles = theme => ({
    section: {
        py: 3,
        background: '#000435'
    },
    logo: {
        maxHeight: '45px'
    },
    link: {
        color: 'text.primary',
        textDecoration: 'none'
    },
})

const Footer = () => {

    const styles = makeStyles()

    return (
        <Box component="section" sx={styles.section}>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item xs={12} lg={4} marginBottom={{xs: 2}}>
                        <Box component="img" src={Logo} sx={styles.logo} />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <Stack direction="column" rowGap={1}>
                            <Typography variant="h6" fontWeight="bold" color="secondary">
                                Legal
                            </Typography>
                            <Link to="/privacyPolicy" style={styles.link}>
                                <Typography variant="body1" sx={styles.link} >
                                    Privacy Policy
                                </Typography>
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <Stack direction="column" rowGap={1}>
                            <Typography variant="h6" fontWeight="bold" color="secondary">
                                Contact Us
                            </Typography>
                            <Link to="tel:01917432274" target="_blank" style={styles.link}>
                                <Typography variant="body1" sx={styles.link} >
                                    Phone Number
                                </Typography>
                            </Link>
                            <Link to="https://www.facebook.com/stargaterentals/" target="_blank" style={styles.link}>
                                <Typography variant="body1" sx={styles.link} >
                                    Facebook
                                </Typography>
                            </Link>
                            <Link to="mailto:support@stargaterentals.com" target="_blank" style={styles.link}>
                                <Typography variant="body1" sx={styles.link} >
                                    Email
                                </Typography>
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer