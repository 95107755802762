import { useState, useEffect } from "react"
import { Box, Container, Grid, Typography, Pagination } from "@mui/material"

import { describeFAQs, fetchFAQs } from "../../../services/backend"
import QuestionAnswer from "../../../components/QuestionAnswer"

const makeStyles = theme => ({
    section: {
        py: 5
    }
})

const PAGE_SIZE = 24

const QuestionAnswerList = () => {

    const styles = makeStyles()

    const [description, setDescription] = useState()
    const [faqs, setFAQs] = useState([])

    useEffect(() => {
        describeFAQs()
            .then(({data: description}) => setDescription({
                ...description,
                pages: Math.ceil(description.faqs / PAGE_SIZE),
                currentPage: 1
            }))
    }, [])

    useEffect(() => {
        if (!description)
            return

        fetchFAQs(description.currentPage, PAGE_SIZE)
            .then(({data: {faqs}}) => setFAQs(faqs))
            .catch(console.log)
    }, [description])

    return (
        <Box component="section" sx={styles.section} >
            <Container maxWidth="lg">
                {!!faqs && !!description && (
                    <Typography variant="body2" textAlign="right" mb={2}>
                        Showing {faqs.length} of {description.faqs} questions
                    </Typography>
                )}

                <Grid container spacing={2} mb={2}>
                    {faqs.map((faq) => (
                        <QuestionAnswer {...faq} key={faq._id.toString()} />
                    ))}
                </Grid>

                <Box component="div" display="flex" justifyContent="center">
                    <Pagination 
                        color="primary" 
                        count={description?.pages || 1}
                        page={description?.currentPage || 1}
                        onChange={(undefined, page) => setDescription(_description => ({..._description, currentPage: page}))}            
                    />
                </Box>
            </Container>
        </Box>
    )
}

export default QuestionAnswerList