import { CssBaseline, ThemeProvider } from "@mui/material"

import Theme from "../../assets/Theme"
import Navbar from "../../components/Navbar"
import Header from "./components/Header"
import FleetPage from "./components/FleetPage"

const Fleet = () => {

    return (
        <ThemeProvider theme={Theme.Light}>
            <CssBaseline />

            <ThemeProvider theme={Theme.Dark}>
                <Navbar />
                <Header />
            </ThemeProvider>

            <FleetPage />
        </ThemeProvider>
    )
}

export default Fleet