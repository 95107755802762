import { ThemeProvider, Container, Paper, Typography, Box } from "@mui/material"

import Theme from '../../assets/Theme'
import Header from "./components/Header"
import Navbar from "../../components/Navbar"

const makeStyles = (theme) => ({
    section: {
        py: 5
    },
})

const PrivacyPolicy = () => {

    const styles = makeStyles()

    return (
        <ThemeProvider theme={Theme.Light}>
            <ThemeProvider theme={Theme.Dark}>
                <Navbar />
                <Header />
            </ThemeProvider>

            <Box component="section" sx={styles.section}>
                <Container maxWidth="md" style={{ marginTop: '20px' }}>
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                    Clerk Solutions Limited Privacy Policy
                    </Typography>
                    <Typography variant="subtitle1" mb={2} gutterBottom>
                    Effective Date: 01/02/2024
                    </Typography>

                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                    1. Information We Collect
                    </Typography>
                    <Typography variant="body1">
                    a. <strong>Personal Information:</strong> We may collect...
                    </Typography>
                    <Typography variant="body1" mb={2}>
                    b. <strong>Non-Personal Information:</strong> We may automatically collect...
                    </Typography>

                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                    2. How We Use Your Information
                    </Typography>
                    <Typography variant="body1">
                    a. We use the information you provide to fulfill your requests...
                    </Typography>
                    <Typography variant="body1" mb={2}>
                    b. Non-personal information is used for analytics, to enhance user experience...
                    </Typography>

                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                    3. Cookies and Similar Technologies
                    </Typography>
                    <Typography variant="body1">
                    a. We use cookies and similar technologies to collect non-personal information...
                    </Typography>
                    <Typography variant="body1" mb={2}>
                    b. You can manage your cookie preferences through your browser settings.
                    </Typography>

                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                    4. Third-Party Links
                    </Typography>
                    <Typography variant="body1">
                    a. Our website may contain links to third-party websites...
                    </Typography>
                    <Typography variant="body1" mb={2}>
                    b. We are not responsible for the privacy practices or content of these sites...
                    </Typography>

                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                    10. Contact Us
                    </Typography>
                    <Typography variant="body1">
                    a. If you have any questions or concerns about this Privacy Policy, please contact us at [insert contact information].
                    </Typography>
                </Container>
            </Box>
        </ThemeProvider>
    )
}

export default PrivacyPolicy