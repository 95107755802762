import { ThemeProvider, CssBaseline } from "@mui/material"

import Theme from "../../assets/Theme"
import Navbar from "../../components/Navbar"
import Header from "./components/Header"
import QuestionAnswerList from "./components/QuestionAnswerList"

const FAQs = () => {

    return (
        <ThemeProvider theme={Theme.Light}>
            <ThemeProvider theme={Theme.Dark}>
                <Navbar />
                <Header />
            </ThemeProvider>

            <QuestionAnswerList />
        </ThemeProvider>
    )
}

export default FAQs