import { createContext, useContext, useState } from "react"
import { useTheme, Button, Modal, Paper, Stack, Typography, Box, Avatar } from "@mui/material"
import { ChevronRight } from "@mui/icons-material"

const SetPopupContext = createContext()

export const useSetPopup = () => {
    return useContext(SetPopupContext)
}

const makeStyles = theme => ({
    paper: {
        width: `calc(100% - ${theme.spacing(2)} * 2)`,
        maxWidth: '500px',
        padding: 2,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none'
    },
    continue: {
        mt: 2,
        textAlign: 'right',
        '.MuiButton-root': {
            paddingRight: 0,
            paddingLeft: 0,
            '&:hover': {
                background: 'transparent',
            }
        },
        '.MuiAvatar-root': {
            width: '30px',
            height: '30px',
            background: '#000435'
        }
    }
})

const Popup = ({children}) => {

    const theme = useTheme()
    const styles = makeStyles(theme)

    const [popup, setPopup] = useState()

    const onClose = () => {
        if (!!popup.onClose)
            popup.onClose()

        setPopup(undefined)
    }

    return (
        <SetPopupContext.Provider value={setPopup}>
            {children}
            <Modal
                open={!!popup}
                onClose={() => onClose(popup)}
            >
                <Paper sx={styles.paper}>
                    <Stack direction="column" rowGap={2}>
                        <Typography variant="h4" fontWeight="bold" textAlign="center">
                            {popup?.title}
                        </Typography>
                        <Typography variant="body1" textAlign="justify">
                            {popup?.message}
                        </Typography>

                        <Box component="div" sx={styles.continue} onClick={() => onClose(popup)}>
                            <Button disableRipple>
                                Continue
                                &nbsp;
                                <Avatar>
                                    <ChevronRight />
                                </Avatar>
                            </Button>
                        </Box>
                    </Stack>
                </Paper>
            </Modal>
        </SetPopupContext.Provider>
    )
}

export default Popup