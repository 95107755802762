import { createTheme } from "@mui/material";

const Light = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#000435'
        },
        secondary: {
            main: '#00A3FF'
        },
    },
    shape: {
        borderRadius: 0
    },
    zIndex:{
        drawer: 1100,
        appBar: 1200
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                    boxShadow: 'none'
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        }
    }
})

const Dark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000435'
        },
        secondary: {
            main: '#00A3FF'
        }
    },
    shape: {
        borderRadius: 0
    },
    zIndex:{
        drawer: 1100,
        appBar: 1200
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                    boxShadow: 'none'
                }
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: '#000435'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
    }
})

export default {
    Light, Dark
}