import { Box, Typography } from "@mui/material"

const makeStyles = theme => ({
    section: {
        py: 1,
        px: 2,
        background: '#000324'
    }
})

const Disclaimer = () => {

    const styles = makeStyles()

    return (
        <Box component="section" sx={styles.section}>
            <Typography variant="body2" textAlign="center" color="text.primary">
                &copy; Clerk Solutions Limited, 2024 All Rights Reserved.
            </Typography>
        </Box>
    )
}

export default Disclaimer