import { Link } from "react-router-dom"
import { Box, Button, Divider, Paper, Stack, Typography, useTheme } from "@mui/material"

const makeStyles = theme => ({
    card: {
        flexBasis: `calc(33% - ${theme.spacing(2)})`,
        minWidth: {
            xs: '100%',
            lg: '0'
        },
        p: 2,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: 'secondary.main'
    },
    cover: image => ({
        width: '100%',
        paddingBottom: '55%',
        backgroundImage: `url('${image}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }),
    property: {
        padding: 1,
    },
    actions: {
        'a': {
            flex: 1,
        },
        '.MuiButton-root': {
            width: '100%'
        }
    }
})

const CarCard = ({_id, aos, coverUrl, name, properties, price, available}) => {

    const theme = useTheme()

    const styles = makeStyles(theme)

    return (
        <Paper variant="outlined" sx={styles.card} data-aos={aos} data-aos-duration="1000">
            <Stack direction="column" rowGap={2}>
                <Box component="div" sx={styles.cover(coverUrl)} />
                <Typography variant="h6" fontWeight="bold" textAlign="left">
                    {name}
                </Typography>
                <Box component="div">
                    {properties.map((property, index) => (
                        <Box component="div" key={`${_id.toString()}_${property.name}`}> 
                            <Stack direction="row" columnGap={2} sx={styles.property}>
                                <Typography variant="body1" fontWeight="bold">
                                    {property.name}
                                </Typography>
                                <Typography variant="body1">
                                    {property.value}
                                </Typography>
                            </Stack>
                            <Divider />
                        </Box>
                    ))}
                    <Box component="div"> 
                        <Stack direction="row" columnGap={2} sx={styles.property}>
                            <Typography variant="body1" fontWeight="bold">
                                Availability
                            </Typography>
                            <Typography variant="body1">
                                {available == 0 && 'Available soon' || available == 1 && 'Available now' || 'Currently unavailable'}
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
                <Stack direction="row" columnGap={1} alignItems="flex-end">
                    <Typography variant="h6" fontWeight="bold" textAlign="left" color="secondary" padding={0} margin={0}>
                        £{price.toFixed(2)}
                    </Typography>
                    <Typography variant="caption">
                        per week
                    </Typography>
                </Stack>
                <Stack direction="row" columnGap={2} sx={styles.actions}>
                    <Link to="tel:01917432274">
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                        >
                            Call
                        </Button>
                    </Link>
                    <Link to="https://www.facebook.com/stargaterentals" target="_blank">
                        <Button
                            size="large"
                            variant="contained"
                            color="secondary"
                        >
                            Facebook
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default CarCard 