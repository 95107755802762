import { ThemeProvider, CssBaseline } from "@mui/material"

import Theme from "../../assets/Theme"
import Navbar from "../../components/Navbar"
import Header from "./components/Header"
import AvailableCars from "./components/AvailableCars"
import Contact from "./components/Contact"
import ContactFormModal from "../../components/Popup"
import FAQs from "./components/FAQs"

const Home = () => {

    return (
        <ThemeProvider theme={Theme.Light}>
            <CssBaseline />
            <ThemeProvider theme={Theme.Dark}>
                <Navbar />
                <Header />
            </ThemeProvider>
            <AvailableCars />
            <ThemeProvider theme={Theme.Dark}>
                <Contact />
            </ThemeProvider>
            <ContactFormModal />
            <FAQs />
        </ThemeProvider>
    )
}

export default Home