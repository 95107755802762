import { Close, Expand, ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, Collapse, Grid, IconButton, Paper, Typography } from "@mui/material"
import { useState } from "react"

const makeStyles = theme => ({
    question: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
        borderColor: 'secondary'
    }
})

const QuestionAnswer = ({question, answer}) => {

    const [expanded, setExpanded] = useState(false)

    const styles = makeStyles()

    return (
        <Grid item xs={12} lg={6}>
            <Paper variant="outlined" sx={styles.question}>
                <Typography variant="h6" fontWeight="bold" textAlign="left">
                    {question}
                </Typography>
                <IconButton onClick={() => setExpanded(_expanded => !_expanded)}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Paper>
            <Collapse in={expanded}>
                <Typography variant="body1" textAlign="justify" marginTop={2}>
                    {answer}
                </Typography>
            </Collapse>
        </Grid>
    )
}

export default QuestionAnswer